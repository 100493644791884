<template lang="pug">
q-page(padding)
  .text-h3.text-center.q-mb-xl.q-mt-lg.text-secondary {{ $t('explanatory-statement') }}

  .text-center.text-body2 40963 "Orchestration of distributed innovation training in the volunteer sector"
  
  .row.text-left.q-mt-lg(style="margin-left: 10vw; margin-right: 10vw")
    .col-3
      strong Contact: Tom Bartindale
      div Department of Human Centred Computing
      div Faculty of Information Technology
      div Monash University
      div Clayton
      div tom.bartindale@monash.edu
    .col-12
      .text-body1.q-mt-lg.q-mb-md {{ $t('research1') }}
      
      .text-h4 {{ $t('research2') }}

      .text-body1.q-mt-lg.q-mb-md {{ $t('research3') }}
      .text-body1.q-mt-lg.q-mb-md {{ $t('research4') }}
      .text-body1.q-mt-lg.q-mb-md {{ $t('research5') }}
      .text-body1.q-mt-lg.q-mb-md {{ $t('research6') }}

      .text-h4 {{ $t('research7') }}
      
      .text-body1.q-mt-lg.q-mb-md {{ $t('research8') }}

      .text-h4 {{ $t('research9') }}

      .text-body1.q-mt-lg.q-mb-md {{ $t('research10') }}
      .text-body1.q-mt-lg.q-mb-md {{ $t('research11') }}
      .text-body1.q-mt-lg.q-mb-md {{ $t('research12') }}

      .text-h4 {{ $t('research13') }} 

      .text-body1.q-mt-lg.q-mb-md {{ $t('research14') }}
      .text-body1.q-mt-lg.q-mb-md {{ $t('research15') }}
      .text-body1.q-mt-lg.q-mb-md {{ $t('research16') }}

      .text-h4 {{ $t('research17') }}

      .text-body1.q-mt-lg.q-mb-md {{ $t('research18') }}

      .text-body1.q-mt-lg.q-mb-md {{ $t('research19') }}

      .text-body1.q-mt-lg.q-mb-md {{ $t('research20') }}

      .text-h4 {{ $t('research21') }}

      .text-body1.q-mt-lg.q-mb-md {{ $t('research22') }}

      .text-h4 {{ $t('research23') }}

      .text-body1.q-mt-lg.q-mb-md {{ $t('research24') }}

      .text-h4 {{ $t('research25') }}

      .text-body1.q-mt-lg.q-mb-md {{ $t('research26') }}

      .text-body1.q-mt-lg.q-mb-md {{ $t('research27') }}

      .text-h4 {{ $t('research28') }}
      
      .text-body1.q-mt-lg.q-mb-md {{ $t('research29') }}

      .row.q-mt-md
        .col-3
          strong Executive Officer
          div Monash University Human Research Ethics Committee (MUHREC) 
          div Room 111, Chancellery Building D,
          div 26 Sports Walk, Clayton Campus
          div Monash University VIC 3800, AUSTRALIA
          div Tel: +61 3 9905 2052
          div Email: muhrec@monash.edu
          div Fax: +61 3 9905 3831 
      
</template>

<script>
  export default {
    computed: {
      messages() {
        return this.$i18n.messages[this.$i18n.locale];
      },
    },
  };
</script>
